import * as React from 'react'
import { graphql } from 'gatsby'
const { useEffect } = React;
import { Element } from 'react-scroll'
import { isMobile } from "react-device-detect";
import WebFont from "webfontloader";
import { cardColors } from '../../styles/cards'

import Page from '../../components/Page'
import IndexLayout from '../../layouts'
import { Recollection3a, CommonContainerProps } from '../../components/cards/recollection/index'
import noise from '../../img/bg/bkg-noise.png'

const IndexPage: React.FC<CommonContainerProps> = ({ data }) => {

  useEffect(() => {
    WebFont.load({
      typekit: {
        id: 'vsu8loo'
      }
    });
  });

  useEffect(() => {
    if (!isMobile) {
      window.location.replace("https://google.com")
    }
  });

  useEffect(() => {
    function setBounceColor() {
      document.body.style.backgroundColor = '#' + cardColors.anoko;
      document.body.style.backgroundImage = noise!;
    }

    document.addEventListener("scroll", setBounceColor, { passive: false });

    return () => {
      document.removeEventListener("scroll", setBounceColor, false);
    };
  }, []);

  return (
    <IndexLayout>
      <Page>
        <Element name={`scroll-to-element-0`} className='element' key={`element-0`}>
          <Recollection3a
            containerHeight={'100vh'}
            photo={data.photos.edges[0]}
            award={data.award}
            all={data.all.edges}
            desktop={data.desktop} />
        </Element>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage

/* eslint no-undef: off */
export const pageQuery = graphql`
  query Recollection3aQuery {
    desktop: file(relativePath: {eq: "bg/bkg-noise.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    award: file(relativePath: {eq: "recollection/3a/3a.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    photos: allFile(filter: {relativeDirectory: {eq: "recollection_main/3a" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }

    all: allFile(filter: {relativeDirectory: {eq: "recollection_all/3a" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
}
`; ``
